import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <header>
        <h2>Huiru Yang</h2>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Huiru. I am a Computer Science student at <a href="https://www.khoury.northeastern.edu/">Northeastern University</a>, a growing tech enthusiast, a former architectural designer. My interest includes databases, distributed systems, and cloud computing, stems from my strong belief that the rapid advancement of technologies that reshaping our daily life require a solid foundation by these computing components.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Huiru Yang <Link to="/">huiruyang.works</Link>.</p>
    </section>
  </section>
);

export default SideBar;
