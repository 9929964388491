const routes = [
  {
    index: true,
    label: 'Huiru Yang',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Posts',
    path: '/posts',
  },
  {
    label: 'More',
    path: '/more',
  },
];

export default routes;
